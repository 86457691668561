<script setup>
const routes = [
  {
    title: "t-form-edit-staff",
    to: "/forms/t-form-edit-staff",
    icon: "",
    subroutes: [],
  },
];
</script>

<template>
  <div
    class="w-full max-w-screen min-h-screen max-h-screen overflow-hidden areas"
  >
    <r-sidebar :routes="routes" class="area-aside" @close-menu="closeMenu" />

    <div class="area-main px-12 bg-white overflow-y-auto">
      <!-- <r-header-info class="area-header mb-8" /> -->
      <div class="mt-8 container">
        <slot />
      </div>
    </div>
  </div>
</template>

<style scoped>
.areas {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 180px 1fr;
  grid-template-areas:
    "aside  main"
    "aside  main";
}

.area-header {
  grid-area: header;
}

.area-aside {
  grid-area: aside;
}

.area-main {
  grid-area: main;
}
</style>
