<script setup>
const route = useRoute();

const props = defineProps({
  routes: { type: [Object] },
});

const routePath = computed(() => "/" + route.path.split("/")[1]);

const isExpanded = ref(true);

const start = ref(false);

const emit = defineEmits(["close-menu"]);

function clickMenu() {
  isExpanded.value = !isExpanded.value;
  localStorage.setItem("isExpanded", isExpanded.value);
  start.value = true;
  emit("close-menu", isExpanded.value);
}
</script>

<template>
  <aside
    class="relative area-aside bg-gray-100 pl-6 pr-2 flex flex-col justify-between py-10 border-r-gray-200 border-r"
    :class="[
      isExpanded && start ? 'openMenu' : '',
      !isExpanded && start ? 'closeMenu' : '',
      isExpanded && !start ? 'w-80' : '',
      !isExpanded && !start ? 'w-19' : '',
    ]"
  >
    <nav>
      <div class="flex flex-row h-15 justify-start items-center space-x-5">
        <nuxt-link to="/" class="bg-none">
          <Transition name="fade">
            <t-icon-logo
              v-show="isExpanded"
              class="w-42 h-9 pb-1.5"
            ></t-icon-logo>
          </Transition>
        </nuxt-link>
      </div>
      <ul class="flex flex-col space-y-8 mt-12 w-full justify-start">
        <template v-for="(item, index) in routes" :key="index">
          <li v-if="item">
            <nuxt-link
              :to="item.to"
              class="w-fit flex items-center space-x-5"
              exact-active-class="text-primary-400"
            >
              <component
                v-bind:is="item.icon"
                class="w-6 h-6"
                style="flex-shrink: 0"
                :active="routePath === item.to"
              ></component>

              <Transition name="fade">
                <div
                  class="text-lg font-sf-pro whitespace-nowrap font-semibold"
                  :class="routePath === item.to ? 'text-primary-400' : ''"
                  v-show="isExpanded"
                >
                  {{ item.title }}
                </div>
              </Transition>
            </nuxt-link>
          </li>
        </template>
      </ul>
    </nav>
  </aside>
</template>

<style scoped>
.closeMenu {
  animation-name: animation0;
  animation-duration: 0.4s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  width: 320px;
  animation-fill-mode: forwards;
}

@keyframes animation0 {
  0% {
    width: 320px;
  }

  100% {
    width: 75px;
  }
}

.openMenu {
  animation-name: animation1;
  animation-duration: 0.4s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  width: 75px;
  animation-fill-mode: forwards;
}

@keyframes animation1 {
  0% {
    width: 75px;
  }

  100% {
    width: 320px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.4s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}

a.router-link-active {
  background-color: inherit !important;
}

a.router-link-exact-active {
  background-color: inherit !important;
}

.areas {
  display: grid;
  grid-template-columns: 320px 1fr;
  grid-template-rows: 180px 1fr;
  grid-template-areas:
    "aside  header"
    "aside  main";
}

aside a.router-link-active {
  --tw-bg-opacity: 1;
  background-color: rgb(249 249 249 / var(--tw-bg-opacity));
}
</style>
